var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfo
    }
  }, [_c('b-card', {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.apiFile + _vm.snpbInfo.thumbnail
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h1', {
    staticClass: "text-dark"
  }, [_c('b', [_vm._v(_vm._s(_vm.snpbInfo.name) + " ")])]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.snpbInfo.description)
    }
  }), _c('br'), _vm.dataMemberBeli && _vm.dataMemberBeli.is_expired == false ? _c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.daftar
    }
  }, [_vm._v(" Masuk ")]) : _vm.dataMemberBeli && _vm.dataMemberBeli.is_expired == true ? _c('div', {}, [_c('b-button', {
    attrs: {
      "variant": "outline-info",
      "disabled": ""
    }
  }, [_vm._v(" Masuk ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.daftarLagi
    }
  }, [_vm._v(" Daftar Lagi ")])], 1) : _c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.daftarSNBP
    }
  }, [_vm._v(" Daftar SNBP ")])], 1)], 1)], 1), _vm.dataMemberBeli ? _c('b-card', {
    staticClass: "border"
  }, [_c('center', [_c('h6', [_vm.dataMemberBeli.submit_count > 0 ? _c('span', [_vm._v(" Anda telah men-submit sebanyak " + _vm._s(_vm.dataMemberBeli.submit_count) + "x ")]) : _c('span', [_vm._v(" Anda belum men-submit SNBP, silahkan klik tombol \"Masuk\" untuk mencoba layanan SNBP ")])])])], 1) : _vm._e()], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.listSnbp[0] != null ? _c('b-card', [_c('List', {
    attrs: {
      "data": _vm.listSnbp
    }
  })], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "header-bg-variant": "light-warning",
      "id": "modal-tambah",
      "centered": "",
      "title": "Peringatan!",
      "hide-footer": ""
    },
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('p', [_vm._v(" Kamu belum mengisi profil diri kamu! Silahkan isi dengan mengklik tombol dibawah ini ")]), _c('center', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "to": {
        name: 'profil'
      }
    }
  }, [_vm._v("Profil")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }