var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Daftar History")])])], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "my-1 col"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-1 col"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.data
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }, {
      key: "cell(school_origin_info)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value.nama_sekolah ? data.value.nama_sekolah : data.item.school_origin) + " ")];
      }
    }, {
      key: "cell(school_accreditation)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }, {
      key: "cell(created_at)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDate(data.value)) + " ")];
      }
    }, {
      key: "cell(detail)",
      fn: function fn(data) {
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.detail(data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1)];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.data.length,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }