<template>
  <div class="">
    <b-overlay :show="loadingInfo">
      <b-card class="" style="margin-top: 30px">
        <b-row>
          <b-col md="6">
            <b-img
              :src="apiFile + snpbInfo.thumbnail"
              class="img-fluid"
            ></b-img>
          </b-col>
          <b-col md="6">
            <h1 class="text-dark">
              <b>{{ snpbInfo.name }} </b>
            </h1>
            <p v-html="snpbInfo.description"></p>
            <br />
            <b-button
              class=""
              variant="outline-info"
              @click="daftar"
              v-if="dataMemberBeli && dataMemberBeli.is_expired == false"
            >
              Masuk
            </b-button>
            <div
              class=""
              v-else-if="dataMemberBeli && dataMemberBeli.is_expired == true"
            >
              <b-button class="" variant="outline-info" disabled>
                Masuk
              </b-button>
              <b-button class="ml-2" variant="info" @click="daftarLagi">
                Daftar Lagi
              </b-button>
            </div>
            <b-button
              class=""
              variant="outline-info"
              @click="daftarSNBP"
              v-else
            >
              Daftar SNBP
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="border" v-if="dataMemberBeli">
        <center>
          <h6>
            <span v-if="dataMemberBeli.submit_count > 0">
              Anda telah men-submit sebanyak {{ dataMemberBeli.submit_count }}x
            </span>
            <span v-else>
              Anda belum men-submit SNBP, silahkan klik tombol "Masuk" untuk
              mencoba layanan SNBP
            </span>
          </h6>
        </center>
      </b-card>
    </b-overlay>
    <b-overlay :show="loading">
      <b-card v-if="listSnbp[0] != null">
        <List :data="listSnbp" />
      </b-card>
    </b-overlay>
    <b-modal
      header-bg-variant="light-warning"
      v-model="Modal"
      id="modal-tambah"
      centered
      title="Peringatan!"
      hide-footer
    >
      <p>
        Kamu belum mengisi profil diri kamu! Silahkan isi dengan mengklik tombol
        dibawah ini
      </p>
      <center>
        <b-button variant="primary" :to="{ name: 'profil' }">Profil</b-button>
      </center>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BButton,
  BLink,
  BImg,
  BRow,
  BCol,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import List from "./List";

export default {
  components: {
    BCard,
    BModal,
    BButton,
    BLink,
    BImg,
    BRow,
    BCol,
    BOverlay,
    List,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      listSnbp: [],
      snpbInfo: {},
      dataMemberBeli: null,
      loading: false,
      loadingInfo: false,
      Modal: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {
        user_id: this.user.id,
      };
      this.$store
        .dispatch("snbp/list", params)
        .then((res) => {
          this.loading = false;
          this.listSnbp = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getSnbpInfo() {
      this.loadingInfo = true;
      let params = {};
      this.$store
        .dispatch("snbp/indexSetting", params)
        .then((res) => {
          this.loadingInfo = false;
          this.snpbInfo = res.data;
        })
        .catch((err) => {
          this.loadingInfo = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    cekMemberBeli() {
      let user_id = this.user.id;
      this.$store
        .dispatch("snbp/memberCek", user_id)
        .then((res) => {
          this.dataMemberBeli = res.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info! `,
              icon: "AlertIcon",
              variant: "warning",
              text: err.response.data.message,
            },
          });
        });
    },
    daftarSNBP() {
      // :to="{ name: 'payment-snbp' }"
      this.$router.push({ name: "payment-snbp" });
    },
    daftarLagi() {
      this.$swal({
        title: "Anda Yakin Daftar Lagi?",
        text: "Daftar akan melakukan pembayaran lagi!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Iya",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        let payload = {
          member_snbp_id: this.dataMemberBeli.id,
        };

        this.$store
          .dispatch("snbp/resetMember", payload)
          .then(async (res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Sukses! `,
                icon: "AlertIcon",
                variant: "success",
                text: "Berhasil, anda bisa daftar lagi",
              },
            });
            this.getData();
            await this.getSnbpInfo();
            this.$router.push({ name: "payment-snbp" });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info! `,
                icon: "AlertIcon",
                variant: "warning",
                text: err.response.data.message,
              },
            });
          });
      });
    },
    daftar() {
      if (this.user.detail == null) {
        this.Modal = true;
      } else {
        this.$router.push({ name: "snbp-daftar" });
      }
    },
  },

  created() {
    this.getData();
    this.getSnbpInfo();
    this.cekMemberBeli();
  },
};
</script>

<style></style>
